<template>
  <page-section class="RelationsOverview" hide-title>
    <template #above-title>
      <dashboard-filters-v2 class="m-b-m">
        <template #primary>
          <search-input />
        </template>

        <template #secondary>
          <!-- <dashboard-filters-v2-item>
            <filter-category-picker />
          </dashboard-filters-v2-item>

          <dashboard-filters-v2-item>
            <filter-country-picker />
          </dashboard-filters-v2-item> -->

          <dashboard-filters-v2-item>
            <filter-sort-by />
          </dashboard-filters-v2-item>

          <div class="DashboardFiltersV2-ButtonsContainer">
            <restricted-button
              :to="{ name: 'connect.relations.create' }"
              class="m-l-a"
              permission="relation.create"
              action-class="button is-dark"
            >
              {{ $t('components.the_header.add_relation') }}
            </restricted-button>
          </div>
        </template>
      </dashboard-filters-v2>
    </template>
    <load-more
      ref="relations"
      :endpoint="endpoint"
      :placeholder-items="2"
      width="column is-6"
      placeholder="MediaProfilePlaceholder"
    >
      <template
        slot="item"
        slot-scope="props"
      >
        <relation-card
          :relation="props.item"
          @relation-unlinked="$refs.relations.removeItem"
        />
      </template>
    </load-more>
  </page-section>
</template>

<script>
import { mapGetters } from 'vuex'

import RelationCard from '@/components/connect/relations/Card'
import SearchInput from '@/components/connect/_filters/SearchInput'
import { buildUrlWithParams } from '@hypefactors/shared/js/utils/UrlUtils'
// import FilterCountryPicker from '@hypefactors/shared/js/components/filters/DashboardFiltersCountryPicker'
// import FilterCategoryPicker from '@/components/connect/_filters/CategoriesFilter'
import FilterSortBy from '@/components/connect/_filters/SortBy'

export default {
  components: {
    SearchInput,
    RelationCard,
    // FilterCategoryPicker,
    // FilterCountryPicker,
    FilterSortBy
  },

  computed: {
    ...mapGetters(['getFilter']),

    endpoint () {
      return buildUrlWithParams('connect/relations', {
        brand: this.activeBrandId,
        search: this.getFilter('searchQuery'),
        sort: this.getFilter('sortBy'),
        categories: this.getFilter('categories'),
        countries: this.getFilter('country')
      })
    }
  },

  watch: {
    activeBrandId () {
      this.fetchGroups()
    }
  },

  created () {
    this.fetchGroups()
  },

  methods: {
    async fetchGroups () {
      this.$store.dispatch('connect/groups/fetch', this.activeBrandId)
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.topbar {
  border-bottom: 2px solid $grey-dark;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}
</style>
